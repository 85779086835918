import React from 'react';
import PropertyDemo, { demoKey } from '../components/PropertyDemo';

export default function ({}) {
  return (
    <PropertyDemo
      profileKey={demoKey.demo1.key}
      demoName={demoKey.demo1.title}
    />
  );
}
